import * as React from "react"
type SpinnerIconType = {
  className?: string
}
export const SpinnerIcon = (props: SpinnerIconType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      className={props?.className}
    >
      <path
        d="M4.5801 7.438L7.7301 4.54V5.854L4.1121 9.112L0.494102 5.854V4.54L3.5361 7.348V6.88C3.5361 3.154 6.1461 0.508 10.0881 0.508C11.9601 0.508 13.5441 1.156 14.6601 2.272L13.9581 2.974C13.0221 1.984 11.6721 1.408 10.0881 1.408C6.7761 1.408 4.5801 3.676 4.5801 6.88V7.438ZM15.8841 4.648L19.5021 7.906V9.22L16.4601 6.412V6.88C16.4601 10.606 13.8501 13.252 9.9081 13.252C8.0901 13.252 6.5241 12.622 5.4081 11.56L6.1101 10.858C7.0461 11.794 8.3601 12.352 9.9081 12.352C13.2201 12.352 15.4161 10.084 15.4161 6.88V6.322L12.2661 9.22V7.906L15.8841 4.648Z"
        fill="#0F0F14"
      />
    </svg>
  )
}
