import { useMsal } from "@azure/msal-react"
import React, { useState } from "react"
import LoadingModal from "../Modal/LoadingModal"
import { right, saveButton, underlineButton } from "../Styling/Buttons"
import { Link } from "gatsby"
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query"
import axios from "axios"
import { authToken } from "../Security/authToken"
import { postApi } from "../Utils/WebApi-utils"
import { toast } from "react-toastify"
import { inputField } from "../Styling/InputField"
import { SpinnerIcon } from "../Graphics/SpinnerIcon"

export type OTPType = {
  id: string
  oTP: string
  username: string
  isLicensedEntraUser: boolean
  oTPStatus: number
}

export const OTP = () => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const [loading, setLoading] = useState(false)
  const [loadingError, setLoadingError] = useState(false)

  const GetOTP = (account, inProgress, instance) =>
    useQuery(
      "getOTP",
      async () => {
        return axios
          .get(process.env.GATSBY_APP_WEBAPI + "HefContacts/OTP", {
            headers: {
              "Conent-Type": "application/json",
              "Ocp-Apim-Subscription-Key":
                process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
              Authorization:
                "Bearer " + (await authToken(account, inProgress, instance)),
            },
          })
          .then(res => {
            const data = res.data
            if (data.oTPStatus === 1) {
              setTimeout(() => {
                MyOTP.refetch()
              }, 5000)
            }
            return data
          })
      },
      {
        refetchOnWindowFocus: false,
        retry: (count, error) => {
          if (count >= 3) {
            setLoadingError(true)
            return false
          }
          return true
        },
      }
    )

  //   MyOTP.data.oTPStatus values
  //   Active = 0,
  //   Processing = 1
  //   Inactive = 2
  const MyOTP: UseQueryResult<OTPType> = GetOTP(accounts, inProgress, instance)

  const CreateOTP = useMutation(
    async values =>
      postApi(
        process.env.GATSBY_APP_WEBAPI + "HefContacts/OTP",
        values,
        accounts,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: (data, values) => {
        toast.success("Forespørselen ble sendt", {
          bodyClassName: "bg-blue text-white",
          theme: "colored",
          style: { backgroundColor: "#004C97" },
        })
        queryClient.invalidateQueries(["getOTP"])
        MyOTP.refetch()
        setLoading(false)
      },
      onError: data => {
        toast.error("Noe gikk galt med forespørselen!")
        setLoading(false)
      },
    }
  )

  // Request the one time password
  const startOTPRequest = () => {
    setLoading(true)
    CreateOTP.mutate()
  }

  return (
    <div>
      {MyOTP.isLoading || loading ? (
        <LoadingModal />
      ) : (
        <div id="UserAccount">
          <div>
            {loadingError ? (
              <div>
                <p className="font-sans">
                  Det har skjedd en feil, prøv igjen senere
                </p>
              </div>
            ) : (
              <div className=" font-sans pb-10">
                <div className="flex flex-col">
                  <p>
                    Brukernavn:{" "}
                    <span className=" font-semibold ">
                      {MyOTP?.data?.username}
                    </span>
                  </p>
                </div>
                <div className=" mb-8 ">
                  <Link
                    to={
                      "https://learn.didac.no/human/Public/ViewMicroCourseAnonymously?id=25ba5c40-2097-468d-b446-44616c3ef3e4"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className={underlineButton}
                  >
                    Brukerveiledning innlogging
                  </Link>
                </div>

                {/* Status = 0 / Active. Show new password */}
                {MyOTP?.data?.oTPStatus === 0 && (
                  <div className="bg-white w-full md:w-1/2 p-4 flex flex-col">
                    <p className=" font-semibold mb-1 ">
                      Her er ditt nye passord
                    </p>
                    <input
                      className={inputField + " disabled:bg-gray-light"}
                      disabled={true}
                      value={MyOTP?.data?.oTP}
                    />
                  </div>
                )}

                {/* Status = 1 / Processing. Waiting for new password */}
                {MyOTP?.data?.oTPStatus === 1 && (
                  <div className="bg-white w-full md:w-1/2 p-4 flex flex-col">
                    <p className=" font-semibold mb-1 ">
                      Vi genererer et nytt passord
                    </p>
                    <div className="flex flex-row mt-2 gap-2 items-center ">
                      <SpinnerIcon className=" animate-spin -scale-100 " />
                      <p className=" mb-0 ">
                        Dette kan ta inntil til ett minutt
                      </p>
                    </div>
                  </div>
                )}

                <div className=" flex flex-row items-center mt-8">
                  <Link
                    to={"https://humanetisk.sharepoint.com/sites/Ekstranett/"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={saveButton + right + " mb-0 "}
                  >
                    Gå til Ressurssidene
                  </Link>

                  {/* Status = 2 / Inactive. Ask for new password */}
                  {MyOTP?.data?.oTPStatus === 2 && (
                    <button
                      onClick={startOTPRequest}
                      className={underlineButton + " pb "}
                    >
                      Tilbakestill passord
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
